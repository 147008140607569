<script setup lang="ts">
import Icon from './Icon.vue';
import type { Size } from './Icon.types';

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'basic' | 'nav';
export type Usage = 'danger' | 'disabled' | 'active' | 'success';

interface Props {
  variant?: Variant;
  usage?: Usage;
  label?: string;
  iconOnly?: boolean;
  icon?: string;
  iconSize?: Size;
  iconRight?: string;
  strokeWidth?: number;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  usage: undefined,
  label: '',
  iconOnly: false,
  icon: undefined,
  iconSize: undefined,
  iconRight: undefined,
  strokeWidth: undefined,
});

const disabled = ref(props.usage == 'disabled');

const usageVariant: ComputedRef<string | undefined> = computed(() => {
  if (!props.usage) return;

  switch (props.variant) {
    case 'primary':
    case 'secondary':
      if (props.usage === 'danger') return '-danger';
      if (props.usage === 'disabled') return '-disabled';
      if (props.usage === 'success') return '-success';
      break;
    case 'tertiary':
      if (props.usage === 'disabled') return '-disabled';
      break;
    case 'quaternary':
      break;
    case 'nav':
      if (props.usage === 'active') return '-active';
      break;
    default:
      return `-${[props.usage]}`;
  }
});
</script>
<template>
  <button
    :class="['c-button', `-${variant}`, usageVariant, { '-round': props.iconOnly }] "
    :disabled="disabled"
  >
    <slot name="icon" />
    <slot />
    <Icon
      v-if="!$slots.icon && icon"
      :name="icon"
      class="c-button__icon c-button__icon-left"
      :size="iconSize"
      :stroke-width="strokeWidth"
    />
    <span
      v-if="!props.iconOnly"
      class="c-button__label"
    >{{ label }}</span>
    <Icon
      v-if="iconRight"
      :name="iconRight"
      class="c-button__icon c-button__icon-right"
      :size="iconSize"
    />
  </button>
</template>
